import { Injectable } from '@angular/core';

import * as angular from 'angular';
import { downgradeInjectable } from '@angular/upgrade/static';

import { TrackerService } from 'src/app/components/logging/tracker.service';
import { OpenidConnectLoader } from 'src/app/ajs-upgraded-providers';

@Injectable({
  providedIn: 'root'
})
export class OpenidConnectService {

  constructor(
    private trackerService: TrackerService,
    private openidConnectLoader: OpenidConnectLoader
  ) {
    this.openidConnectLoader()
      .then((userManager) => {
        var trackOpenidEvent = (openidEventType, eventProperties?) => {
          return userManager.getUser()
            .then((user) => {
              this.trackerService.openidEvent(openidEventType, user ? user.profile : {}, eventProperties);
            });
        };

        userManager.events.addUserLoaded((user) => {
          this.trackerService.openidEvent('user loaded', user.profile);
        });
        userManager.events.addUserUnloaded(() => {
          trackOpenidEvent('user unloaded');
        });
        userManager.events.addAccessTokenExpiring(() => {
          trackOpenidEvent('access token expiring');
        });
        // Temporarily removed to avoid sending too many events
        // userManager.events.addAccessTokenExpired(() => {
        //   trackOpenidEvent('access token expired');
        // });
        userManager.events.addSilentRenewError((error) => {
          trackOpenidEvent('silent renew error', {
            errorMessage: error.message
          });
        });
        userManager.events.addUserSignedOut(() => {
          trackOpenidEvent('user signed out');
        });
      });
  }

  getUser() {
    return this.openidConnectLoader()
      .then((userManager) => {
        return userManager.getUser();
      })
      .then((user) => {
        console.log('get user response', user);

        return user;
      }).catch((err) => {
        console.log(err);

        throw err;
      });
  }

  signinRedirect(state) {
    return this.openidConnectLoader()
      .then((userManager) => {
        this.trackerService.openidEvent('sign in redirect started');
        return userManager.signinRedirect({
          state: state
        });
      })
      .then((resp) => {
        console.log('signin redirect response', resp);
      }).catch((err) => {
        console.log(err);

        throw err;
      });
  }

  signinRedirectCallback(location?) {
    console.log("openidConnectService.signinRedirectCallback called");
    return this.openidConnectLoader()
      .then((userManager) => {
        return userManager.signinRedirectCallback(location);
      })
      .then((user) => {
        console.log('signin redirect response', user);

        return user;
      }).catch((err) => {
        console.error('Authentication Error from Redirect: ', err);

        this.trackerService.openidEvent('sign in redirect failed', {}, {
          errorMessage: err && err.message
        });

        throw err;
      });
  }

  signinPopup() {
    return Promise.reject('Not implemented');
  }

  signinSilent(username?) {
    if (!username) {
      return Promise.reject('Missing user id');
    }

    return this.openidConnectLoader()
      .then((userManager) => {
        return userManager.signinSilent({
          login_hint: username
        });
      })
      .then((user) => {
        console.log('signin silent response', user);

        return user;
      }).catch((err) => {
        console.log(err);

        throw err;
      });
  }

  removeUser() {
    return this.openidConnectLoader()
      .then((userManager) => {
        return userManager.removeUser();
      });
  }

}

angular.module('risevision.apps.services')
  .factory('openidConnect', downgradeInjectable(OpenidConnectService));