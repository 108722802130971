<!-- Color -->
<div class="background-color-picker">
  <div class="background-color-picker-input form-group" style="position:relative;">
    <color-picker [(ngModel)]="background.color" ngDefaultControl></color-picker>
  </div>
</div>

<!-- Image Choice -->
<div class="form-group">
  <div class="flex-row">
    <mat-checkbox
      #backgroundUseImage
      name="visibility"
      id="backgroundUseImage"
      ngDefaultControl
      (ngModelChange)="useImageChange($event)"
      [ngModel]="background.useImage">
    </mat-checkbox>
    <label id="backgroundUseImageLabel" for="backgroundUseImage-input" (click)="useImageChange(!background.useImage)">Use Image</label>
  </div>
</div>

@if (background.useImage && background.image) {
  <div id="backgroundImageControls">
    <!-- Image Placeholder -->
    <div class="form-group">
      @if (!imageLoaded) {
        <div class="image-placeholder">
          <i class="fa fa-image"></i>
        </div>
      }
      <!-- Image -->
      <div [shown]="imageLoaded" class="row">
        <div class="col-xs-4">
          <img
            [src]="imageUrl"
            (error)="imageLoaded = false"
            alt="Background image preview"
            class="img-rounded img-responsive">
        </div>
      </div>
    </div>
    <!-- Image URL -->
    <rv-url-field id="backgroundImageUrl"
      name="imageUrl"
      #urlField="ngModel"
      ngDefaultControl
      [class.big-error-text]="urlField.control.hasError('httpUrl')"
      [(ngModel)]="background.image.url"
      (ngModelChange)="setImageUrl($event)"
      urlPatternValidator
      httpValidator
      urlFileTypeValidator
      acceptedFileTypes="IMAGES"
      required
    />
  
    <div class="row">
      <div class="col-sm-6">
        <div class="form-group">
          <label class="control-label">Image Position:</label>
          <mat-form-field appearance="outline">
            <select matNativeControl name="position" [(ngModel)]="background.image.position" class="form-control">
              <option value="top-left">Top Left</option>
              <option value="top-center">Top Center</option>
              <option value="top-right">Top Right</option>
              <option value="middle-left">Middle Left</option>
              <option value="middle-center">Middle Center</option>
              <option value="middle-right">Middle Right</option>
              <option value="bottom-left">Bottom Left</option>
              <option value="bottom-center">Bottom Center</option>
              <option value="bottom-right">Bottom Right</option>
            </select>
          </mat-form-field>
        </div>
      </div>
  
      <div class="col-sm-6">
        <div class="form-group">
          <label class="control-label">Image Repeat:</label>
          <mat-form-field appearance="outline">
            <select matNativeControl name="repeat" [(ngModel)]="background.image.repeat" class="form-control" [disabled]="background.image.scale">
              <option value="no-repeat">None</option>
              <option value="repeat-y">Vertical</option>
              <option value="repeat-x">Horizontal</option>
              <option value="repeat">Both</option>
            </select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <!-- Scale to fit -->
    <div class="form-group">
      <div class="flex-row">
        <mat-checkbox name="scale" ngDefaultControl [(ngModel)]="background.image.scale">Scale To Fit</mat-checkbox>
      </div>
    </div>
  </div>
}
